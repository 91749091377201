var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.sessionList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(topic)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.topic.title)+" ")]}},{key:"cell(mentor)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"10px"}},[_c('img',{staticClass:"mentor__image",attrs:{"src":item.mentor.user_information.gallery.length
              ? item.mentor.user_information.gallery[0]
              : item.mentor.avatar ||
                require("@/assets/images/avatars/null-profile.webp"),"alt":item.mentor.name}}),_vm._v(" "+_vm._s(item.mentor.name)+" ")])]}},{key:"cell(mentee)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"10px"}},[_c('img',{staticClass:"mentor__image",attrs:{"src":item.user.avatar ||
            require("@/assets/images/avatars/null-profile.webp"),"alt":item.user.name}}),_vm._v(" "+_vm._s(item.user.name)+" ")])]}},{key:"cell(date)",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.date)+" "),_c('div',{staticClass:"d-flex"},[_vm._v(_vm._s(item.start_time)+" - "+_vm._s(item.end_time))])]}}])}),(_vm.resultData.itemsPerPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1)+" to "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1 * _vm.resultData.itemCount)+" from "+_vm._s(_vm.resultData.totalItems))]),_c('b-pagination',{staticClass:"justify-content-end",attrs:{"total-rows":_vm.resultData.totalItems,"per-page":_vm.resultData.itemsPerPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPageList),callback:function ($$v) {_vm.currentPageList=$$v},expression:"currentPageList"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }