<template>
  <b-card title="Mentors Session List">
    <b-col cols="12 row text-left" class="p-0 mt-1">
      <b-col cols="col-2 md-2 sm-6 ml-1">
        <b-button
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-b-modal.form-export-modal
        >
          Export Mentor
        </b-button>
      </b-col>
    </b-col>
    <b-row class="mt-1">
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="mentor">Mentor:</label>
          <v-select
            id="mentor"
            label="name"
            v-model="filter.mentor_uuid"
            :options="mentorData"
            placeholder="-- Pilih --"
            :reduce="(mentorData) => mentorData.uuid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="mentee">Mentee:</label>
          <v-select
            id="mentee"
            label="name"
            v-model="filter.user_uuid"
            :options="userData"
            placeholder="-- Pilih / Ketik --"
            :reduce="(userData) => userData.uuid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            @keyup.stop.native="searchUser"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <label for="start-date">Date Range:</label>
        <div class="form-group row align-items-center">
          <b-col cols="12" lg="5">
            <flat-pickr
              id="start-date"
              placeholder="Start Date"
              v-model="filter.date_range.start_date"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
              }"
            />
          </b-col>
          -
          <b-col cols="12" lg="5">
            <flat-pickr
              id="end-date"
              placeholder="End Date"
              v-model="filter.date_range.end_date"
              class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
              }"
            />
          </b-col>
          <!-- <label for="start-date">Date Range:</label>
          <flat-pickr
            id="start-date"
            v-model="filter.date_range.start_date"
            class="form-control"
            :config="{
              dateFormat: 'Y-m-d',
            }"
          /> -->
        </div>
      </b-col>
      <!-- <b-col cols="12" lg="3">
        <div class="form-group">
          <label for="end-date">End Date:</label>
          <flat-pickr
            id="end-date"
            v-model="filter.date_range.end_date"
            class="form-control"
            :config="{
              dateFormat: 'Y-m-d',
            }"
          />
        </div>
      </b-col> -->
    </b-row>

    <Table
      :result="result"
      :sessionData="sessionData"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :current-page="currentPage"
    />

    <Modal
      :get-history="getHistory"
      :get-columns="getColumns"
      :history="histories"
      :columns="columns"
      :filterParams="filter"
    />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import Table from "@/components/session/Table.vue";
import Modal from "@/components/users/Modal.vue";
import flatPickr from "vue-flatpickr-component";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
    flatPickr,
    vSelect,
    Modal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      sessionData: {},
      groupList: [],
      mentorData: [],
      userData: [],
      filter: {
        mentor_uuid: "",
        user_uuid: "",
        dateRange: "",
        date_range: {
          start_date: "",
          end_date: "",
        },
      },
      histories: [],
      columns: [],
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.getMentor();
    this.getMentee();
  },
  methods: {
    getHistory() {
      this.$http
        .get("/api/v1/admin/export/history/mentorship-booked-schedules")
        .then((response) => {
          this.histories = response.data.data;
          console.log(this.histories, "ini histories dari session");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumns() {
      this.$http
        .get("/api/v1/admin/export/column?type=mentorship-booked-schedules")
        .then((response) => {
          this.columns = response.data.data.shift().columns;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;

      const params = {};
      this.filter.page = page;
      if (this.filter.page) {
        params.page = this.filter.page;
      }
      if (this.filter.mentor_uuid) {
        params.mentor_uuid = this.filter.mentor_uuid;
      }
      if (this.filter.user_uuid) {
        params.user_uuid = this.filter.user_uuid;
      }
      if (
        this.filter.date_range.start_date &&
        this.filter.date_range.end_date
      ) {
        params["date_range[start_date]"] = this.filter.date_range.start_date;
        params["date_range[end_date]"] = this.filter.date_range.end_date;
      }
      this.$http
        .get("/api/v1/admin/mentorship/booked-schedules", {
          params: params,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.sessionData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMentor() {
      this.$http
        .get("api/v1/admin/users?group=mentorship-mentor")
        .then((response) => {
          this.mentorData = response.data.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchUser: _.debounce(function (e) {
      const search = e.target.value;
      this.getMentee(search);
    }, 300),

    getMentee(search) {
      this.$http
        .get("api/v1/admin/users", {
          params: {
            search: search != null ? search : null,
          },
        })
        .then((response) => {
          this.userData = response.data.data.items;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Users?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/users/remove/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Users successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
