<template>
    <b-modal
        title="Export User"
        id="form-export-modal"
        centered
        size="lg"
        no-close-on-backdrop
        hide-footer
    >
      <div class="form-group">
        <label>Pilih Colum yang Akan di Export: </label>
        <v-select
          v-model="selectedColumn"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="label"
          multiple
          placeholder="-- Pilih --"
          :options="columnsList"
          :reduce="(columnsList) => columnsList.column"
        />
      </div>
      <b-table
          striped
          hover
          :items="historyList"
          :fields="fields"
          :busy="isLoadingExportHistory"
          show-empty
          responsive
          >
          <template #table-busy>
              <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
              </div>
          </template>

          <template v-slot:cell(no)="{ index }">
              {{ index + 1 }}
          </template>
          <template v-slot:cell(file)="{ item }">
            <a :href="item.file" target="_blank" rel="noopener noreferrer" v-if="item.status == 'done'">Download</a>
          </template>
          <template v-slot:cell(columns)="{ item }">
            {{  item.columns.map(column => column.label).join(', ') }}
          </template>
          <template v-slot:cell(payload)="{ item }">
            {{  item.payload  }}
          </template>
      </b-table>
      <div class="text-right mb-2">
        <b-button
          @click="exportUser()"
          variant="success"
          class="btn waves-effect waves-float waves-light btn-success"
          v-bind:disabled="selectedColumn.length <= 0"
        >
          Export Sekarang
        </b-button>
      </div>
    </b-modal>
</template>

<script>
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
import vSelect from "vue-select";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      historyList: [],
      columnsList: [],
      fields: [
          { key: "no" },
          { key: "created_at", label: "Export Time" },
          { key: "status", label: "Status" },
          { key: "file" },
          { key: "payload", label: "Active Filter" },
          { key: "notes", label: "Notes" },
          { key: "columns", label: "Columns" },
      ],
      isLoadingExportHistory: false,
      validations: "",
      selectedColumn: [],
      filterValue: {},
    }
  },
  mounted() {
    this.getColumns();
    this.getHistory();
  },
  props: {
    getHistory: {
        type: Function
    },
    getColumns: {
        type: Function
    },
    history: {
        type: "",
    },
    columns: {
        type: "",
    },
    filterParams: {
      type: "",
    }
  },
  watch: {
    history(value) {
      this.historyList = value;
    },
    columns(value) {
      this.columnsList = value;
      this.selectedColumn = value.map((column => column.column))
    },
    filterParams(value) {
      this.filterValue = value;
    }
  },
  created() {
  },
  methods: {
    exportUser() {
      const filterKeys = [
        'group', 'referrer_code', 'search', 'register_start_date', 'register_end_date', 'last_active_start_date',
        'last_active_end_date', 'status', 'age', 'operator'
      ];

      let payload = {
        columns:[],
      };

      let params = {};
      //Set form data for params
      for (const param of filterKeys) {
        const paramPayload = this.filterValue[param];
        if (!paramPayload) {
          continue;
        }
        params[param] = paramPayload;
      }
      payload['params'] = params;

      //Set form data for columns
      for (let index = 0; index < this.selectedColumn.length; index++) {
        const selectedColumn = this.selectedColumn[index];
        const columnPayload = this.columnsList.filter((column) => {
          return column.column == selectedColumn
        })?.shift();
        payload.columns[index] = {
          label: columnPayload.label,
          column: columnPayload.column
        };
      }

      this.isLoadingExportHistory = true;
      let exportData = this.$route.name;
      this.$http
        .post(`/api/v1/admin/export/process/${exportData}`, payload)
        .then((res) => {
          this.isLoadingExportHistory = false;
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Data sedang di export di background, mohon tunggu beberapa saat.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          }).then((result) => {
            if (result.isConfirmed) { 
              this.$bvModal.hide("form-export-modal");
              this.getHistory()
            }
          });
        })
        .catch((error) => {
          this.isLoadingExportHistory = false;
          this.$bvModal.hide("form-export-modal");
        })
    },
  },
}
</script>