<template>
  <div>
    <b-table
      striped
      hover
      :items="sessionList"
      :fields="fields"
      :busy="isLoadingComponent"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(topic)="{ item }">
        {{ item.topic.title }}
      </template>

      <template v-slot:cell(mentor)="{ item }">
        <!-- {{ item.mentor.title }} -->
        <div class="d-flex align-items-center" style="gap: 10px">
          <img
            :src="
              item.mentor.user_information.gallery.length
                ? item.mentor.user_information.gallery[0]
                : item.mentor.avatar ||
                  require(`@/assets/images/avatars/null-profile.webp`)
            "
            class="mentor__image"
            :alt="item.mentor.name"
          />
          {{ item.mentor.name }}
        </div>
      </template>

      <template v-slot:cell(mentee)="{ item }">
        <!-- {{ item.mentor.title }} -->
        <div class="d-flex align-items-center" style="gap: 10px">
          <img
            :src="
              item.user.avatar ||
              require(`@/assets/images/avatars/null-profile.webp`)
            "
            class="mentor__image"
            :alt="item.user.name"
          />
          {{ item.user.name }}
        </div>
      </template>

      <template v-slot:cell(date)="{ item }">
        {{ item.date }}
        <div class="d-flex">{{ item.start_time }} - {{ item.end_time }}</div>
      </template>
    </b-table>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    sessionData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    sessionData(value) {
      this.sessionList = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      moment,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      sessionList: [],
      fields: [
        { key: "no" },
        { key: "topic" },
        { key: "mentor" },
        { key: "mentee" },
        { key: "date" },
      ],
    };
  },
  computed: {
    rows() {
      return this.sessionList.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.mentor__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
